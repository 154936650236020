import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby";
import { gsap } from "gsap";


function currentPage(target) {
  const linkPathName = new URL(target.getAttribute('href'), window.location.origin).pathname
  return linkPathName !== window.location.pathname
}
const Link = ({ children,action = () => null, ...props }) => {
  const onClick = (event) => {
    event.preventDefault();
    action()
    setTimeout(function(){ document.body.style.backgroundColor = 'inherit' ; }, 4000);
    document.body.classList.add('is-loading');
    if (currentPage(event.target)) {
      gsap.fromTo(".page-will--translate", { y: 0 }, { y: 60, opacity: 0, duration: .85, ease: "power3.inOut", onComplete: function () { navigate(event.target.getAttribute('href'), { state: { internal: true } }) } });
    }
  }
  return (
    <GatsbyLink {...props} onClick={(event) => onClick(event)}>
      {children}
    </GatsbyLink>
  )
}
export default Link