import React from "react"
import Link from "../components/link"

const Menu = () => {

  const openOverlay = () => {
    document.querySelector('.overlay--form').classList.add('active');
    document.querySelector('.overlay--background').classList.add('active'); 
  }

  const openMenu = () => {
    document.querySelector('.menu-button').classList.toggle('open');
    document.querySelector('.mobile--menu').classList.toggle('active');
  }

  const closeMenu = () => {
    setTimeout(function(){ 
      document.querySelector('.menu-button').classList.remove('open');
      document.querySelector('.mobile--menu').classList.remove('active');
    }, 500); 
  }
 
  return (
  <>
    <button className='menu-button' onClick={(event) => openMenu(event)} onKeyDown={(event) => openMenu(event)}>
      <div className='menu-button-inner' >
      <div className='menu-button-top' />
      <div className='menu-button-bottom' />
      </div> 
    </button>
    <div className={"menu text-right"}>
      <Link activeClassName='active' className="menu--link spacing" to="/folio">Folio</Link>
      <Link activeClassName='active' className="menu--link spacing" to="/about">About</Link>
      <a  className="menu--link external--link spacing" target='_blank' rel="noreferrer" href="https://graceelizabethstore.com.au">Art Store</a>
      <p  className="menu--link spacing" role='presentation' onClick={(event) => openOverlay(event)} onKeyDown={(event) => openOverlay(event)}>Contact</p>
    </div> 
    <div className={"mobile--menu"}>
      <div className="internal p20 text-center mb80 medium"> 
        <Link className="menu--link spacing" action={closeMenu} to="/folio">Folio</Link>
        <Link className="menu--link spacing" action={closeMenu} to="/about">About</Link>
        <a className="menu--link external--link spacing" target='_blank' rel="noreferrer" href="https://graceelizabethstore.com.au">Art Store</a>
        <p className="menu--link spacing" role='presentation' onClick={(event) => openOverlay(event)} onKeyDown={(event) => openOverlay(event)}>Contact</p>
      </div>
    </div> 
  </>
  )
}
export default Menu
 