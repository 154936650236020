import * as React from "react"
import Menu from "../components/menu"
import Link from "../components/link"

const Header = () => {
  
  const closeMenu = () => {
    document.querySelector('.menu-button').classList.remove('open');
    document.querySelector('.mobile--menu').classList.remove('active');
  }

  return (
    <header className="masthead">
      <div className="inner flex max-1600 ma">
        <div className="logo mra">
          <Link className="flex" action={closeMenu} to="/"> 
            <div className="main--logo"></div>
          </Link>
        </div>
        <Menu />
      </div>
    </header> 
  ) 
}

export default Header
