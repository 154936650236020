import React from 'react';
import Header from '../components/header'

const PersistHeader = ({ element, props }) => {
    return (
        <>
            <Header />
            {element}
        </>
    )
}

export default PersistHeader